import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC } from 'react';
import { LinkWithIcon } from '../../../components/Link/LinkWithIcon.component';
import { RoundedCard } from '../../../components/RoundedCard/RoundedCard';
import ListDocsDevis from '../ListDocsDevis';

interface DocumentationRowProps {
  proposedQuotes: any;
  quoteMobileDisplay: number;
  rowClassName?: string;
  documents: any;
}

const DocumentationRow: FC<DocumentationRowProps> = ({
  proposedQuotes,
  quoteMobileDisplay,
  rowClassName,
  documents
}) => {
  const getDevisUrl = (quoteNumber: string) => {
    const document = documents.find(
      (doc: any) => doc.proprietes?.DEVIS_NUMERO === quoteNumber && doc.categories.includes('DEVIS')
    );
    return document?.url;
  };

  return (
    <div className={'row-documentation'}>
      <div className={'title'}>
        <h3 className={'form-font-large'}>Documentation</h3>
      </div>
      <div className={'content'}>
        {proposedQuotes?.map((quote: any, index: number) => {
          if (!getDevisUrl(quote.numeroInterne)) {
            return <></>;
          }

          return (
            <div
              key={quote.numeroInterne}
              className={classNames(
                'item',
                { [`mobile-displayed__${index}`]: index === quoteMobileDisplay },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              {getDevisUrl(quote.numeroInterne) && (
                <RoundedCard
                  key={quote.numeroInterne}
                  className={classNames(
                    'primary',
                    'card',
                    'proposition',
                    {
                      [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                    },
                    {
                      [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                    },
                    `_${index}`
                  )}
                >
                  <LinkWithIcon
                    className={'primary'}
                    icon={<FontAwesomeIcon size={'lg'} icon={faExternalLinkAlt} />}
                    labelLink="Voir la proposition"
                    link={getDevisUrl(quote.numeroInterne)}
                  />
                </RoundedCard>
              )}
              <ListDocsDevis documents={documents} quote={quote} className={rowClassName} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentationRow;
