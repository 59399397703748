import React, { ReactElement, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useLocation } from 'react-router';
import { FormProps, StepCodeAdhesion } from 'types';
import { ButtonCard } from '../../components/Button/ButtonCard';
import { ColoredText } from '../../components/ColoredText/ColoredText';
import Separator from '../../components/Separator/Separator.component';
import './FormDemandeAdhesionStepSignature.scss';
import { fetchLibelleCodif } from '../../fetches/options.fetch';
import useCallApi from '../../hooks/useCallAPI.hook';
import { useFetch } from '../../hooks/useFetch.hook';
import { getCodifLabelFromOptionsIfExist, getUrlParam, lireFractionnementByPeriod } from '../../utils';
import ListDocsDevis from './ListDocsDevis';

const formatMontant = (montant: number) => {
  const splitMontant = montant.toFixed(2).split('.');
  return (
    <>
      {splitMontant[0]},<small>{splitMontant[1] ?? '00'}</small> €
    </>
  );
};

export default function FormDemandeAdhesionStepSignature(props: FormProps<any>): ReactElement {
  const { initialFormValues } = props;
  const [procedureSignature, setProcedureSignature] = useState(initialFormValues?.procedureSignature);
  const location = useLocation();
  const typologies = useFetch(fetchLibelleCodif);
  const caracteristiquesCodif = useFetch(() =>
    fetchLibelleCodif('CARACTERISTIQUE_DEVIS_' + initialFormValues?.typologieLabel.split(':')[0])
  );

  const handleNextClick = () => {
    window.open(procedureSignature?.urlSignature, '_blank');
    props.goNextStep({ stepCode: StepCodeAdhesion.SIGNATURE });
  };

  const getProcedureSignatureFromAPI = useCallApi<any, any>(
    () =>
      fetch(
        `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${getUrlParam(
          location.search,
          'id'
        )}/signature/${getUrlParam(location.search, 'p')}`,
        {
          method: 'get',
          headers: { 'Content-Type': 'application/json' }
        }
      ),
    (data, successCallback) => {
      successCallback && successCallback(data);
    },
    err => {
      console.log(err);
    }
  );

  const handleSuccessGetProcedure = (setter: any) => (data: any) => {
    if (!data.procedureSignature) {
      getProcedureSignatureFromAPI(
        { numeroProjet: initialFormValues?.numeroProjet },
        handleSuccessGetProcedure(setProcedureSignature)
      );
    } else {
      setter(data.procedureSignature);
    }
  };

  useEffect(() => {
    if (!procedureSignature) {
      getProcedureSignatureFromAPI(
        { numeroProjet: initialFormValues?.numeroProjet },
        handleSuccessGetProcedure(setProcedureSignature)
      );
    }
  }, []);

  const selectedQuote = initialFormValues?.proposedQuotes.find(
    (quote: any) => quote.numeroInterne === initialFormValues?.numeroDevisChoisi
  );
  if (!caracteristiquesCodif || !typologies || !selectedQuote) return <></>;

  const isRoedererParticulier =
    selectedQuote.produit.assureur.code === 'ROEDERER' &&
    initialFormValues.typologieLabel === 'SANTE_PARTICULIER:TYPOLOGIE';

  const isHexagone = selectedQuote.produit.assureur.code === 'HEXAGONE';

  return (
    <div className={'adhesion-step-signature form-adhesion'}>
      <div>
        <h2 className={'adhesion-step-signature__title'}>Récapitulatif et signature</h2>

        <div className={'adhesion-step-signature__info'}>
          Au clic sur le bouton “J&apos;accède à la signature en ligne”, vous serez redirigé vers le site sécurisé de
          notre partenaire Yousign.
          <br />
          <br />
          Une fois tous les documents signés, vous recevrez un mail confirmant que nous avons bien reçu vos documents et
          que votre dossier est en cours de traitement.
        </div>

        <div className={'adhesion-step-signature__card'}>
          <div className={'header bold-values'}>
            <div>
              <p className="form-font-large">
                <span>Votre assurance </span>
                <ColoredText
                  text={getCodifLabelFromOptionsIfExist(initialFormValues?.typologieLabel, typologies)}
                  color={'secondary'}
                />
              </p>
            </div>
          </div>
          <div className={'content'}>
            <div className={'recap'}>
              <div className={'recap__row'}>
                <span className={'recap__row__title form-font-regular'}>Assureur</span>
                <span className={'recap__row__content form-font-large text-colored secondary'}>
                  {selectedQuote.produit.assureur.code}
                </span>
              </div>
              <div className={'recap__row'}>
                <span className={'recap__row__title form-font-regular'}>Produit</span>
                <span className={'recap__row__content form-font-large text-colored secondary'}>
                  {selectedQuote.produit.nom}
                </span>
              </div>
              <div className={'recap__row'}>
                <span className={'recap__row__title form-font-regular'}>Formule</span>
                <span className={'recap__row__content form-font-large text-colored secondary'}>
                  {selectedQuote.formule}
                </span>
              </div>
              <div className={'recap__row'}>
                <span className={'recap__row__title form-font-regular'}>Cotisation</span>
                <span className={'recap__row__content form-font-large'}>
                  <b className="text-colored secondary">{formatMontant(+selectedQuote.montant)}</b>
                  <small>
                    {' '}
                    TTC{' '}
                    {lireFractionnementByPeriod(selectedQuote.fractionnementCode) !== '' &&
                      '/ ' + lireFractionnementByPeriod(selectedQuote.fractionnementCode)}
                  </small>
                  {(isRoedererParticulier || isHexagone) && <>*</>}
                </span>
              </div>
              {initialFormValues?.avecFrais === true && (
                <div className={'recap__row'}>
                  <span className={'recap__row__title form-font-regular'}>
                    <b className="form-font-regular">Frais de dossier</b>
                  </span>
                  <span className={'recap__row__content form-font-large text-colored secondary'}>
                    {formatMontant(+initialFormValues?.montantFrais)}
                  </span>
                </div>
              )}
            </div>

            <Separator />
            <div className={'docs'}>
              <ListDocsDevis documents={initialFormValues?.documentsInfos} quote={selectedQuote} />
            </div>

            {(isRoedererParticulier || isHexagone) && (
              <>
                <Separator />
                <div style={{ display: 'block' }}>
                  *{' '}
                  <span className={'form-font-smaller'}>
                    +12 € (à rajouter à la première cotisation mensuelle, cette cotisation unique est un droit d’entrée
                    à l&apos;Association Santé des Régions de France)
                  </span>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={'adhesion-step-signature__signButton'}>
          {!procedureSignature?.urlSignature && (
            <div className={'loading-link-signature'}>
              Récupération du lien de signature en cours . . .
              <Loader type="Rings" color="var(--color-primary)" height={25} width={25} />
            </div>
          )}
          {!!procedureSignature?.urlSignature && (
            <div className="next-button-container">
              <ButtonCard
                className={'primary form-adhesion-next-button no-default-style form-font-regular'}
                onClick={handleNextClick}
              >
                J&apos;accède à la signature en ligne
              </ButtonCard>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
