import { yupResolver } from '@hookform/resolvers/yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useEffect, useRef } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { useFetch } from 'hooks/useFetch.hook';
import {
  fetchCategorieMetierOptions,
  fetchMetierByCategorieOptions,
  fetchStatusProTypeOptions
} from 'fetches/options.fetch';
import { useFetchOptionsOnFieldChange } from 'hooks/useFetchOptionsOnFieldChange.hook';

type Key = 'principal' | 'secondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};

type Emprunteur = {
  emprunteur: {
    porteChargesLourdes: string | undefined;
    travailEnHauteur: string | undefined;
    deplacementsFrequents: string | undefined;
  };
  personne: {
    fumeurRegulier: string | undefined;
    statutProfessionnel: string | undefined;
    categorieProfessionnelle: string | undefined;
    profession: string | undefined;
  };
};

export type FormValues<K extends Key> = PartialRecord<K, Emprunteur>;

export default function FormEmprunteurAssureurs({
  goNextStep,
  initialFormValues,
  formValues,
  setLastFormState,
  showCaptcha,
  setCaptchaToken,
  data = { type: 'principal' }
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;
  const statusProType = useFetch(fetchStatusProTypeOptions);

  const defaultState: FormValues<typeof type> = {
    [type]: {
      emprunteur: {
        porteChargesLourdes: undefined,
        travailEnHauteur: undefined,
        deplacementsFrequents: undefined
      },
      personne: {
        fumeurRegulier: undefined,
        statutProfessionnel: undefined,
        categorieProfessionnelle: undefined,
        profession: undefined
      }
    }
  };

  const validationSchema: Yup.SchemaOf<FormValues<typeof type & any>> = Yup.object({
    [type]: Yup.object({
      emprunteur: Yup.object({
        porteChargesLourdes: Yup.string().typeError('Champ obligatoire'),
        travailEnHauteur: Yup.string().typeError('Champ obligatoire'),
        deplacementsFrequents: Yup.string().typeError('Champ obligatoire')
      }),
      personne: Yup.object({
        fumeurRegulier: Yup.string().typeError('Champ obligatoire'),
        statutProfessionnel: Yup.string().required(),
        categorieProfessionnelle: Yup.string().required(),
        profession: Yup.string().required()
      })
    })
  }).defined();
  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors }
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });
  const assureProfession = watch(`${type}.personne.profession`);
  const isLabelMetierInitialized: any = useRef(false);
  const categoriesMetier = useFetch(fetchCategorieMetierOptions);

  const metiers = useFetchOptionsOnFieldChange(
    watch,
    `${type}.personne.categorieProfessionnelle`,
    (categorie: string) => fetchMetierByCategorieOptions({ categorie: categorie, metier: 'NULL' }),
    input => !!input && input.length > 0
  );

  /*
    @todo Faire en sorte que l'input autocomplete gére valeur/libelle comme un select
    This effect handle first setting of professionCodeLibelle if profession given by initialValues
   */
  useEffect(() => {
    if (!isLabelMetierInitialized.current && assureProfession && metiers) {
      const metier = metiers.find((metier: any) => {
        return metier.value === assureProfession;
      });
      if (metier) {
        setValue(`${type}.personne.profession`, metier.value);
        isLabelMetierInitialized.current = true;
      }
    }
  }, [isLabelMetierInitialized, assureProfession, metiers]);

  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep(data);
  };

  return (
    <div>
      <h1 className="title">
        Informations de l&apos;emprunteur <span>{type}</span>
      </h1>

      <h2 className="subtitle">Informations complémentaires</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        <InputRadioButtonInline
          labelField="L'emprunteur porte t'il des charges lourdes ?"
          {...register(`${type}.emprunteur.porteChargesLourdes`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />
        <InputRadioButtonInline
          labelField="L'emprunteur travaille t'il en hauteur ?"
          {...register(`${type}.emprunteur.travailEnHauteur`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />
        <InputRadioButtonInline
          labelField="L'emprunteur effectue t'il de nombreux déplacements ?"
          {...register(`${type}.emprunteur.deplacementsFrequents`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />
        <InputRadioButtonInline
          labelField="L'emprunteur fume t'il ?"
          {...register(`${type}.personne.fumeurRegulier`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />

        <input {...register(`${type}.personne.profession`)} hidden />

        <InputSelect
          withClearAction
          isSearchable
          label="Catégorie métier"
          name={`${type}.personne.categorieProfessionnelle`}
          control={control}
          errors={errors}
          options={categoriesMetier || []}
          onClear={() => {
            setValue(`${type}.personne.categorieProfessionnelle`, '');
            setValue(`${type}.personne.profession`, '');
          }}
          onSelect={() => {
            isLabelMetierInitialized.current = true;
            setValue(`${type}.personne.profession`, '');
          }}
        />

        <InputSelect
          withClearAction
          isSearchable
          label="Métier"
          name={`${type}.personne.profession`}
          control={control}
          errors={errors}
          options={metiers || []}
        />

        <InputSelect
          label="Statut professionnel"
          name={`${type}.personne.statutProfessionnel`}
          options={statusProType || []}
          control={control}
          errors={errors}
        />

        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      </form>
    </div>
  );
}
